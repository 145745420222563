import React, { Fragment } from "react";

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useQuery } from "@tanstack/react-query";
import { Entry, EntryCollection } from "contentful";
import dayjs from "dayjs";

import ArticlesByBook from "../components/ArticlesByBook";
import ArticlesList from "../components/ArticlesList";
import { IssueCover3D } from "../components/IssueCover3D";
import { NextLinkComposed } from "../components/Link";
import composeIssueTitle from "../functions/composeIssueTitle";
import getBooksWithArticles from "../functions/getBooksWithArticles";
import { Article } from "../types/Article";
import { GeneralContent } from "../types/GeneralContent";
import { Issue } from "../types/Issue";
import { useAuthContext } from "../utils/auth/useAuth";
import { oldTestament, newTestament } from "../utils/books";
import client from "../utils/content/client";

require("dayjs/locale/pt-br");

dayjs.locale("pt-br");

// const searchClient = algoliasearch(
//   process.env.ALGOLIA_APP_ID,
//   process.env.ALGOLIA_API_KEY
// );

// const Hit = ({ hit }) => <Highlight hit={hit} tagName="strong" attribute="fields.title.en-US" />;

// const Hit = ({ hit }) => (
//   <>
//     <p>{hit._highlightResult.fields.title["en-US"].value}</p>
//     <p>{hit._highlightResult.fields.content["en-US"].value}</p>
//   </>
// );

const Index = ({
  content,
  booksWithArticles,
  generalContent,
}: {
  content: { issue: Entry<Issue>; articles: EntryCollection<Article> }[];
  booksWithArticles: any;
  generalContent: Entry<GeneralContent>;
}) => {
  const { user, logout } = useAuthContext();

  React.useEffect(() => {
    window.localStorage.setItem("booksWithArticles", booksWithArticles.join(","));
  }, []);

  const { data: generalContentClientSide } = useQuery({
    queryKey: ["general", generalContent.sys.id],
    queryFn: () => client.getEntry<GeneralContent>(generalContent.sys.id),
    refetchOnWindowFocus: false,
  });

  const { data: lastIssuesWithArticlesLocal } = useQuery({
    queryKey: ["lastIssuesWithArticlesLocal"],
    queryFn: async () => {
      const lastIssues = (
        await client.getEntries<Issue>({
          content_type: "issues",
          order: "-fields.yearMonth",
          limit: 2,
        })
      ).items;

      return Promise.all(
        lastIssues.map(async (issue) => ({
          issue,
          articles: await client.getEntries<Article>({
            content_type: "articles",
            include: 1,
            "fields.issue.sys.id": issue.sys.id,
          }),
        }))
      );
    },
    initialData: content,
  });

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={9}>
        <Paper sx={{ p: 4 }}>
          <Typography variant="h3" gutterBottom>
            Sobre a revista
          </Typography>

          {documentToReactComponents((generalContentClientSide ?? generalContent).fields.about, {
            renderNode: {
              [BLOCKS.PARAGRAPH]: (node, children) => <Typography gutterBottom>{children}</Typography>,
            },
          })}

          <Divider sx={{ my: 4 }} />

          <Typography variant="h3" textAlign="center" sx={{ marginBottom: 4 }}>
            Artigos das últimas edições
          </Typography>

          {lastIssuesWithArticlesLocal?.map(({ issue, articles }, index) => (
            <Fragment key={issue.sys.id}>
              <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                <Box marginRight={3} flexGrow={1}>
                  <Chip
                    component={NextLinkComposed}
                    to={`/edicao/${issue.fields.yearMonth}`}
                    label={composeIssueTitle(issue.fields)}
                    clickable
                  />

                  <ArticlesList items={articles.items} />
                </Box>

                <IssueCover3D
                  coverUrl={issue.fields.cover?.[0]?.url}
                  alt={`capa da edição ${issue.fields.name}`}
                  noBackground
                />
              </Box>

              {index + 1 < lastIssuesWithArticlesLocal.length ? (
                <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
              ) : null}
            </Fragment>
          ))}
        </Paper>
      </Grid>

      <Grid item xs={12} sm={3}>
        {/* <InstantSearch searchClient={searchClient} indexName="articles">
          <SearchBox  />
          <Hits hitComponent={Hit} /> 
        </InstantSearch> */}
        {/* <Paper sx={{ p: 2, mb: 4 }}>
          <TextField
            fullWidth
            // variant="outlined"
            placeholder="Buscar artigo"
            style={{ background: "white", marginBottom: 24 }}
          />
        </Paper> */}

        {/* <Box mb={4} > */}
        <ArticlesByBook
          sx={{ mb: 4 }}
          title="Antigo Testamento"
          books={oldTestament}
          booksWithArticles={booksWithArticles}
        />
        {/* </Box> */}

        <ArticlesByBook title="Novo Testamento" books={newTestament} booksWithArticles={booksWithArticles} />
      </Grid>
    </Grid>
  );
};

export async function getStaticProps() {
  const lastIssues = (
    await client.getEntries({
      content_type: "issues",
      order: "-fields.yearMonth",
      limit: 2,
    })
  ).items;

  const entries = await Promise.all(
    lastIssues.map(async (issue) => ({
      issue,
      articles: await client.getEntries<Article>({
        content_type: "articles",
        include: 1,
        "fields.issue.sys.id": issue.sys.id,
      }),
    }))
  );

  const generalContent = (
    await client.getEntries<GeneralContent>({
      content_type: "general",
      limit: 1,
    })
  ).items[0];

  const booksWithArticles = await getBooksWithArticles();

  return {
    props: {
      content: entries,
      lastIssues,
      booksWithArticles,
      generalContent,
    },
  };
}

export default Index;

import clsx from "clsx";

import styles from "./styles.module.css";

export const IssueCover3D = ({
  coverUrl,
  noBackground = false,
  alt,
}: {
  coverUrl?: string;
  noBackground?: boolean;
  alt: string;
}) => {
  return (
    <div className={clsx(styles.bookContainer, !noBackground && styles.bookContainerBackground)}>
      <div className={styles.book}>{coverUrl ? <img alt={alt} src={coverUrl} /> : <div />}</div>
    </div>
  );
};

import React from "react";

import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import { grey } from "@mui/material/colors";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { MUIStyledCommonProps, SxProps, Theme } from "@mui/system";
import Box from "@mui/system/Box";

import { bookNameToSlugMap } from "../utils/books";

const BooksListWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  padding: theme.spacing(),
  paddingBottom: 40,
  overflow: "hidden",
  position: "relative",
}));

const Book = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0.5),
  flexGrow: 1,
}));

const ExpandButton = styled("div", {
  shouldForwardProp: (prop) => prop[0] !== "$",
})(({ $expanded }: MUIStyledCommonProps<Theme> & { $expanded: boolean }) => {
  return {
    position: "absolute",
    bottom: 0,
    right: 0,
    height: 40,
    width: "100%",
    background: $expanded
      ? "rgba(0,0,0,0)"
      : "linear-gradient(0deg, rgba(240,240,240,1) 0%, rgba(240,240,240,0.9332107843137255) 62%, rgba(255,255,255,0) 100%)",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: grey[600],

    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  };
});

const ArticlesByBook = ({
  title,
  books,
  booksWithArticles,
  sx,
}: {
  title: string;
  books: string[];
  booksWithArticles: string[];
  sx?: SxProps;
}) => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <Box position="relative" sx={{ ...sx }}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Paper>
        <Collapse in={expanded} collapsedSize={200}>
          <BooksListWrapper>
            {books.map((book) => {
              return (
                <Book
                  key={book}
                  variant="outlined"
                  color="secondary"
                  size="small"
                  disabled={!booksWithArticles.includes(book)}
                  href={`/livro/${bookNameToSlugMap[book]}`}
                >
                  {book}
                </Book>
              );
            })}
          </BooksListWrapper>
        </Collapse>
      </Paper>
      {/* {!expanded && ( */}

      {/* )} */}
      <ExpandButton
        onClick={() => {
          setExpanded(!expanded);
        }}
        $expanded={expanded}
      >
        <Typography>mostrar {expanded ? "menos" : "mais"}</Typography>
      </ExpandButton>
    </Box>
  );
};

export default ArticlesByBook;

import Lock from "@mui/icons-material/Lock";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Entry } from "contentful";

import { NextLinkComposed } from "./Link";
import { Article } from "../types/Article";

export default function SimpleList({ items }: { items: Entry<Article>[] }) {
  return (
    <div>
      <List component="nav" aria-label="Artigos">
        {items.map((item) => {
          return (
            <ListItemButton key={item.fields.slug} component={NextLinkComposed} to={`/artigos/${item.fields.slug}`}>
              <ListItemText
                primary={item.fields.title}
                secondary={
                  item.fields.categories?.length ? item.fields.categories.map((cat) => cat.fields.name).join(", ") : ""
                }
              />
              <ListItemIcon style={{ justifyContent: "center" }}>{item.fields.protected ? <Lock /> : ""}</ListItemIcon>
            </ListItemButton>
          );
        })}
      </List>
    </div>
  );
}
